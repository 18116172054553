<script>
import Vue from 'vue'
import { getConfig } from '@/config'
import { mapGetters } from '@/store'
import SimpleNavBar from '../SimpleNavBar.vue'

export default Vue.extend({
    components: { SimpleNavBar },
    computed: {
        ...mapGetters(['userResource', 'instanceURL', 'instanceName']),

        isSimpleNavBar: ({ $route }) => {
            if ($route.name === 'home' || $route.name === 'login') {
                return true
            }
            return false
        },
    },
    methods: {
        reAuth() {
            this.$sigma.logout()
        },
        reloadApp() {
            window.location.pathname = getConfig('HOME_ROUTE')
        },
    },
})
</script>

<template>
    <simple-nav-bar v-if="isSimpleNavBar" />

    <b-navbar v-else toggleable="md" type="dark" variant="dark" fixed="top" class="main-nav">
        <b-navbar-toggle target="nav_collapse" />
        <b-navbar-brand :to="{ name: 'appHome', params: { instanceURL } }"><strong>Pro</strong>Books</b-navbar-brand>
        <b-collapse id="nav_collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item-dropdown
                    v-user-scope-or.hide="['dataentry', 'po_approvals', 'po_approvals_level_2', 'po_approvals_level_3']"
                    text="Transactions"
                    no-caret>
                    <b-dropdown-item :to="{ name: 'purchaseOrderList', params: { instanceURL } }">Purchase Orders</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'checkApprovalList', params: { instanceURL } }">Check Approvals</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'batchList', params: { instanceURL } }">Batches</b-dropdown-item>
                    <!-- <b-dropdown-item :to="{ name: 'accountsPayableList', params: { instanceURL } }">Accounts Payable</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'checkList', params: { instanceURL } }">Checks</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'journalEntryList', params: { instanceURL } }">Journal Entries</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'pettyCashList', params: { instanceURL } }">Petty Cash</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'payrollList', params: { instanceURL } }">Payrolls</b-dropdown-item> -->
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="System" no-caret>
                    <div v-user-scope-or.hide="['payroll']">
                        <b-dropdown-header>Payroll</b-dropdown-header>
                        <b-dropdown-item :to="{ name: 'projectList', params: { instanceURL } }">Projects</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'employeeList', params: { instanceURL } }">Employees</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'assignmentList', params: { instanceURL } }">Assignments</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'agreementList', params: { instanceURL } }">Agreements</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'departmentList', params: { instanceURL } }">Departments</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'eEOClassList', params: { instanceURL } }">EEO Classes</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'employeeDirectDepositRuleList', params: { instanceURL } }">
                            Employee Direct Deposit Rules
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'employeeTypeList', params: { instanceURL } }">Employee Types</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'feeList', params: { instanceURL } }">Fees</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'feeCategoryList', params: { instanceURL } }">Fee Categories</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'feeGroupList', params: { instanceURL } }">Fee Groups</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'fLSATypeList', params: { instanceURL } }">FLSA Types</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'industryList', params: { instanceURL } }">Industries</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'laborUnionList', params: { instanceURL } }">Labor Unions</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'laborUnionLocalList', params: { instanceURL } }">Labor Union Locals</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'payCodeTypeList', params: { instanceURL } }">Pay Code Types</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'productionTypeList', params: { instanceURL } }">Production Types</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'projectFeeAccumulationGroupList', params: { instanceURL } }">
                            Project Fee Accumulation Groups
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'scheduleList', params: { instanceURL } }">Schedules</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'titleList', params: { instanceURL } }">Titles</b-dropdown-item>
                        <b-dropdown-divider />
                    </div>
                    <b-dropdown-item :to="{ name: 'budgetList', params: { instanceURL } }">Budgets</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'countryTaxCodeList', params: { instanceURL } }">Country Tax Codes</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'groupList', params: { instanceURL } }">Groups</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'insuranceList', params: { instanceURL } }">Insurance Codes</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'legislativeTypeList', params: { instanceURL } }">Legislative Types</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'locationList', params: { instanceURL } }">Locations</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'productionList', params: { instanceURL } }">Productions</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'seriesList', params: { instanceURL } }">Series</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'setList', params: { instanceURL } }">Sets</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'userSourceCodeList', params: { instanceURL } }">User Source Codes</b-dropdown-item>
                    <b-dropdown-item v-user-scope-or.hide="['vendor_manage']" :to="{ name: 'vendorList', params: { instanceURL } }"> Vendors </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'wCClassificationList', params: { instanceURL } }">WC Classifications</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'w9List', params: { instanceURL } }">W-9 Forms</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-header>To File</b-dropdown-header>
                    <b-dropdown-item :to="{ name: 'accountList', params: { instanceURL } }">Accounts</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'bankList', params: { instanceURL } }">Banks</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'billableTypeList', params: { instanceURL } }">Billable Types</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'cityList', params: { instanceURL } }">Cities</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'clientList', params: { instanceURL } }">Clients</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'companyList', params: { instanceURL } }">Companies</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'episodeList', params: { instanceURL } }">Episodes</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'actualFeeRateList', params: { instanceURL } }">Fee Rates</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'markupList', params: { instanceURL } }">Markups</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'payCodeList', params: { instanceURL } }">Pay Codes</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'freeFieldList', params: { instanceURL } }">Free Fields</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'salesRepList', params: { instanceURL } }">Sales Reps</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'stateList', params: { instanceURL } }">States</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'stateTaxpayerNumberList', params: { instanceURL } }">State Taxpayer Numbers</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'userList', params: { instanceURL } }">Users</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'oCCCodeList', params: { instanceURL } }">OCC Codes</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'billableItemList', params: { instanceURL } }">Billable Items</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'billableTypeList', params: { instanceURL } }">Billable Types</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'currencyList', params: { instanceURL } }">Currencies</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'depositAccountList', params: { instanceURL } }">Deposit Accounts</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'signatureList', params: { instanceURL } }">Signatures</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Programs" no-caret>
                    <!-- <b-dropdown-item v-user-scope-or.hide="['costreporting']" to="/">Cost Reporting</b-dropdown-item>
                        <b-dropdown-item v-user-scope-or.hide="['post']" to="/">Transaction Posting</b-dropdown-item>
                        <b-dropdown-item v-user-scope-or.hide="['checks']" to="/">Check Processing</b-dropdown-item>
                        <b-dropdown-item v-user-scope-or.hide="['bankrec']" to="/">Bank Reconciliation</b-dropdown-item> -->
                    <b-dropdown-item
                        v-user-scope-or.hide="['po_approvals', 'po_approvals_level_2', 'po_approvals_level_3']"
                        :to="{ name: 'poApprovals', params: { instanceURL } }">
                        PO Approvals
                    </b-dropdown-item>
                    <b-dropdown-item v-user-scope-or.hide="['check_signer']" :to="{ name: 'checkSignatures', params: { instanceURL } }">
                        Check Signatures
                    </b-dropdown-item>
                    <!-- <b-dropdown-item v-user-scope-or.hide="['positivepay']" to="/">Postitive Pay</b-dropdown-item>
                        <span v-user-scope-or.hide="['period_close']">
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-header>Periods</b-dropdown-header>
                            <b-dropdown-item to="/">Period Management</b-dropdown-item>
                            <b-dropdown-item to="/">Close Current Period</b-dropdown-item>
                        </span> -->
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Setup" no-caret>
                    <b-dropdown-item :to="{ name: 'currencyList', params: { instanceURL } }">Currencies</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'aPISettingList', params: { instanceURL } }">Settings</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'help', params: { instanceURL } }">Help</b-dropdown-item>
                </b-nav-item-dropdown>
                <!--
                    <b-nav-item-dropdown v-user-scope-or.hide="['reports']" text="Reports" no-caret>
                        <b-dropdown-item to="/">Cost Reporting</b-dropdown-item>
                        <b-dropdown-item to="/">Trial Balance</b-dropdown-item>
                        <b-dropdown-item to="/">GL Bible Report</b-dropdown-item>
                        <b-dropdown-item to="/">PO Report</b-dropdown-item>
                        <b-dropdown-item to="/">Check Register</b-dropdown-item>
                        <b-dropdown-item to="/">Vendor History Report</b-dropdown-item>
                        <b-dropdown-item to="/">Vendor List</b-dropdown-item>
                        <b-dropdown-item to="/">Chart of Accounts List</b-dropdown-item>
                        <b-dropdown-item to="/">Asset Report</b-dropdown-item>
                        <b-dropdown-item to="/">1099 Tax Reporting</b-dropdown-item>
                        <b-dropdown-item to="/">Audit Report by Account</b-dropdown-item>
                        <b-dropdown-item to="/">Receivable Detail Report</b-dropdown-item>
                    </b-nav-item-dropdown>
                    -->
                <!--
                    <b-nav-item-dropdown v-user-scope-or.hide="['accounts_manage', 'admin']" text="Settings" no-caret>
                        <b-dropdown-item v-user-scope-or.hide="['accounts_manage']" to="/">Chart of Accounts</b-dropdown-item>
                        <span v-user-scope-or.hide="['admin']">
                            <b-dropdown-item to="/">Banks</b-dropdown-item>
                            <b-dropdown-item to="/">Companies</b-dropdown-item>
                            <b-dropdown-item to="/">Productions</b-dropdown-item>
                            <b-dropdown-item to="/">Currencies</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-header>Codes</b-dropdown-header>
                            <b-dropdown-item to="/">Configure Codes</b-dropdown-item>
                        </span>
                    </b-nav-item-dropdown>
                    -->
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown :text="`${userResource} @ ${instanceName}`" no-caret right>
                    <b-dropdown-item :to="{ name: 'login', params: { instanceURL } }" @click="reAuth">Re-Authenticate {{ instanceName }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'home' }">Connect to New Instance</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="reloadApp()">Reload App</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<style lang="scss">
nav.navbar.main-nav {
    position: fixed;
    background: linear-gradient(135deg, #74a8c3 0%, #8363a1 100%);
    margin: 0 0;
    padding: 0 1rem;
    user-select: none;

    .navbar-nav a.nav-link {
        color: rgba(255, 255, 255, 0.75);
    }

    .dropdown:hover {
        background: #7a89b7;
        color: #ffffff;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-item {
        outline: none !important;

        &.active {
            background-color: inherit !important;
            color: inherit !important;
        }

        &:hover,
        &:active,
        &.active:hover {
            background-color: #f3f3f3 !important;
            color: inherit !important;
        }
    }

    .dropdown-menu {
        border-radius: 0 !important;
        box-shadow: 3px 2px 3px #eee;
        margin: 0;
        padding: 0;
        max-height: 65vh;
        overflow-y: auto;

        .dropdown-item.router-link-active {
            background: #ddd !important;
        }

        a.dropdown-item {
            position: relative;

            .checkmark {
                left: 8px;
                position: absolute;
            }
        }
    }

    a.navbar-brand {
        font-size: 100%;
        text-transform: uppercase;
    }
}
</style>
