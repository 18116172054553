<script>
import Vue from 'vue'
import { getConfig } from '@/config'
import { hostnameToInstanceName } from '@sigmacloud/sigma-client/dist/util/instances'

export default Vue.extend({
    name: 'SimpleNavBar',
    components: {},
    data() {
        return {
            clients: [],
            selectedClientHostname: undefined,
        }
    },
    methods: {
        connectTo(clientObj) {
            this.$store.commit('setClient', clientObj.hostname)
            this.reloadApp()
        },
        addNewInstance() {
            this.$refs.loginModal.show()
        },
        login() {
            let loginForm = this.$refs.loginForm
            loginForm.submit()
        },
        logout(hostname) {
            // @todo
            console.log('TO BE IMPLEMENTED: Log Out of', hostname)
        },
        hostnameToInstanceName(hostname) {
            return hostnameToInstanceName(hostname)
        },
        reloadApp() {
            window.location.pathname = getConfig('HOME_ROUTE')
        },
        onLoggedIn() {
            this.$refs.loginModal.hide()
            this.reloadApp()
        },
    },
})
</script>

<template>
    <b-navbar toggleable="md" type="dark" variant="dark" fixed="top" class="main-nav">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand to="/"><strong>Pro</strong>Books</b-navbar-brand>
        <b-collapse id="nav_collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item-dropdown text="Settings" no-caret>
                    <b-dropdown-item @click="addNewInstance()">Connect to New Instance</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<style lang="scss">
nav.navbar.main-nav {
    position: fixed;
    background: linear-gradient(135deg, #74a8c3 0%, #8363a1 100%);
    margin: 0 0;
    padding: 0 1rem;
    user-select: none;

    .navbar-nav a.nav-link {
        color: rgba(255, 255, 255, 0.75);
    }

    .dropdown:hover {
        background: #7a89b7;
        color: #ffffff;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-item {
        outline: none !important;

        &.active {
            background-color: inherit !important;
            color: inherit !important;
        }

        &:hover,
        &:active,
        &.active:hover {
            background-color: #f3f3f3 !important;
            color: inherit !important;
        }
    }

    .dropdown-menu {
        border-radius: 0 !important;
        box-shadow: 3px 2px 3px #eee;
        margin: 0;
        padding: 0;

        .dropdown-item.router-link-active {
            background: #ddd !important;
        }

        a.dropdown-item {
            position: relative;

            .checkmark {
                left: 8px;
                position: absolute;
            }
        }
    }

    a.navbar-brand {
        font-size: 100%;
    }
}
</style>
